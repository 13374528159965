import Accordion from "./Classes/Accordion";
import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import ResponsiveTablesLight from "./Classes/ResponsiveTablesLight";
import CustomSlider from "./Classes/CustomSlider";
import LottieAutoplay from "./Classes/LottieAutoplay";

window.addEventListener("page_event_load", () => {
  new Accordion({
    accordions: '.lc-accordion__inner-head',
    pxCorrectionMobile: 110,
    pxCorrectionDesktop: 105,
    mobileBreakpoint: 1025
  });

  new ResponsiveTablesLight({
    tableComponentSelector: '.lc-component--table',
    tableContainerSelector: '.ns-table-container',
    tableSelector: '.lc-component--table table'
  });

  new LottieAutoplay();

  // slider custom click :)
  const sliders = document.querySelectorAll('.lc-special-slider');
  sliders.forEach(slider => {
    const instance = new Swiper(slider, {
      loop: true,
      modules: [Pagination, Autoplay],

      autoplay: {
        delay: 3500,
        disableOnInteraction: true,
      },

      pagination: {
        enabled: true,
        clickable: true,
        el: '.swiper-pagination',
        type: "bullets"
      }
    });

    const slides = slider.querySelectorAll('.lc-special-slide');
    slides.forEach(slide => {
      slide.addEventListener('click', (e) => {
        instance.autoplay.stop();
      });
    })

  })

});

window.addEventListener('note_event_load', evt => {
  // console.log("note loaded event");
  document.body.classList.add("note-open");

  new ResponsiveTablesLight({
    tableComponentSelector: '.lc-component--table',
    tableContainerSelector: '.ns-table-container',
    tableSelector: '.lc-component--table table'
  });
});


window.addEventListener('note_unloaded', evt => {
  //console.log("note unloaded event");
  document.body.classList.remove("note-open");
});
