import Helper from './Helper';

class Accordion {
  constructor(config = {}) {
    const {
      accordions,
      pxCorrectionMobile,
      pxCorrectionDesktop,
      mobileBreakpoint
    } = config;
    this.trigger = accordions;
    this.pxCorrectionMobile = pxCorrectionMobile;
    this.pxCorrectionDesktop = pxCorrectionDesktop;
    this.mobileBreakpoint = mobileBreakpoint;

    this.accordions = Array.prototype.slice.call(document.querySelectorAll(accordions));
    this.currentEl = null;

    if (this.accordions && this.accordions.length !== 0) {
      this.bindEvents();

      const url = location.href;
      const id = url.split('#');

      if (!!id[1]) {
        const accordionToClick = document.querySelector(`#${id[1]}`);
        const accordionToScroll = document.querySelector('#' + id[1]);
        const headerOffset = document.querySelector('.wp-header').offsetHeight;
        const elementPosition = accordionToScroll.offsetTop;
        const offsetPosition = elementPosition;
        if (accordionToClick) {
          const accordionHeader = accordionToClick.querySelector('.wp-accordion__head');
          const accordionHeaderHeight = accordionHeader.offsetHeight;
          let pxCorrection = "";
          window.addEventListener('load', () => {
            pxCorrection = this.setPxCorrection();
          });

          window.addEventListener('resize', () => {
            pxCorrection = this.setPxCorrection();
          });

          setTimeout(() => {
            accordionHeader.click();
            window.scrollTo({
              top: (offsetPosition - pxCorrection),
              behavior: "smooth"
            });
          }, 1000);
        } else {
          setTimeout(() => {
            window.scrollTo({
              top: (offsetPosition + pxCorrection),
              behavior: "smooth"
            });
          }, 1000);
        }
      }
    }
  }

  bindEvents() {
    this.accordions.forEach(el => {
      el.addEventListener('click', (e) => {
        if (el.classList.contains('active')) {
          Helper.slideClose(el.nextElementSibling);
          el.classList.remove('active');
          el.nextElementSibling.classList.remove('active');
        } else {
          el.classList.add('active');
          Helper.slideOpen(el.nextElementSibling, true);
          el.nextElementSibling.classList.add('active');
        }
      });
    });
  }

  setPxCorrection() {
    if (window.innerWidth >= this.mobileBreakpoint ) {
      return this.pxCorrectionDesktop;
    } else {
      return this.pxCorrectionMobile;
    }
  }
}

export default Accordion;
