import { LottiePlayer } from "@lottiefiles/lottie-player";

class LottieAutoplay {
  constructor() {
    this.lotties = [...document.querySelectorAll('lottie-player')];

    if (this.lotties) {
      this.startObserving();
    }
  }

  startObserving() {
    const options = {
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          entry.target.play();
        }
      });
    };

    this.observer = new IntersectionObserver(observerCallback, options);

    this.lotties.forEach(lottie => {
      this.observer.observe(lottie);
    });
  }
}

export default LottieAutoplay;